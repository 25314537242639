
import React from 'react';

function Contact() {
  return (
    <div>
      <h1>Contact Us</h1>
      <p>Have questions? Reach out to us!</p>
      <p>Phone: 07891108762</p>
        <p>Email:cnwanneka@gmail.com</p>
            {/* Include contact form or contact details */}
    </div>
  );
}

export default Contact;
